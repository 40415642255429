// NOTE: pnpmでのインストールだと@bugsnag/coreを明示的にインストールして参照しないとConfigの型をインポート出来ない
import type { Config } from '@bugsnag/core'
import Bugsnag from '@bugsnag/js'
import { scriptJsonData } from '@/src/lib/json'

const SCRIPT_JSON_USER_ID = 'json-user'

type UserData = {
  id: string
  email: string
}

// NOTE: テスト実行時の notifyReleaseStages 警告が邪魔だからログ無効化
const loggerOptions = (): Pick<Config, 'logger'> => {
  // eslint-disable-next-line unicorn/no-null
  return import.meta.env.RAILS_ENV === 'test' ? { logger: null } : {}
}

const userOptions = (): Pick<Config, 'user'> => {
  const userData = scriptJsonData<UserData>(SCRIPT_JSON_USER_ID)
  if (!userData) return {}

  return {
    user: userData,
  }
}

Bugsnag.start({
  releaseStage: import.meta.env.RAILS_ENV,
  apiKey: 'e05c375c9f1bb44206b609bbb7ec038e',
  enabledReleaseStages: ['production', 'staging'],
  ...loggerOptions(),
  ...userOptions(),
})
